<template>
  <div style="display: flex">
    <el-input
      v-model="part1"
      size="mini"
      @change="changed('part_1', $event)"
    ></el-input>
    <el-input
      v-model="part2"
      size="mini"
      @change="changed('part_2', $event)"
    ></el-input>
    <el-input
      v-model="part3"
      size="mini"
      @change="changed('part_3', $event)"
    ></el-input>
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import requestSender from "@/api/base/requestSender";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-scale-part-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {
      part1: null,
      part2: null,
      part3: null,
    }
  },
  mounted() {
    if( this.scope.row.scale && this.scope.row.scale.current_scale_part ){
      this.part1 = this.scope.row.scale.current_scale_part.part_1;
      this.part2 = this.scope.row.scale.current_scale_part.part_2;
      this.part3 = this.scope.row.scale.current_scale_part.part_3;
    }
  },
  methods: {

    changed(name, value){
      console.log(name, value);
      // return;
      requestSender('post', 'scale/update-single-part-for-card-element', {
        element_id: this.scope.row.id,
        name: name,
        value: value,
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });
        // this.saveElementPropChange(this.scope.row, 'scale', {current_part_id:chosenPartId})
          // this.$emit('update:parts', data.scale_parts);
        })
        .finally(()=>{
          this.$emit('elements-changed');
          // this.loading = false;
        })
    }

    // async selectChanged(chosenPartId){
    //   await this.loadParts();
    //   if( this.part1 && this.part2 && this.part3 ){
    //   }
    //   this.filterPartLists();
    // },


  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
